<template>
  <div style="padding-left:1em">
    <div v-for="(item,ind) in transList" :key="ind" style="margin:.5em 0">
      <div>{{item.content}}</div>
      <TextArea :disabled="!editAble" v-model="item.translation" />
    </div>
  </div>
</template>

<script>
import TransFn from "./baidu-translate";
export default {
  props: {
    editAble: {
      default: true
    },
    transList: {
      type: Array
    }
  },
  methods: {
    initTranslation() {
      if (
        !this.editAble||
        !this.transList ||
        !this.transList[0] ||
        this.transList[0].translation
      ) {
        return;
      }      
      let str = this.transList.reduce(
        (preStr, item) => preStr + "\n" + item.content,
        ""
      );
      TransFn(str).then(data => {
        let res = data.trans_result;
        this.transList.forEach((item, ind) => {
          item.translation = res[ind].dst;
        });
      });
    }
  },
  watch: {
    transList: {
      handler() {
        this.initTranslation();
      },
      immediate: true
    }
  },
  mounted() {}
};
</script>

<style scoped>
</style>